@import 'mixins/main';

.text-danger {
  color: var(--error-500) !important;
}

// pisar colores iconos
.dark-icon svg {
  color: var(--grey-1000) !important;
}

.text-indigo {
  color: var(--indigo-500);

  & mat-icon svg {
    color: var(--indigo-500);
  }
}

.text-disabled {
  color: var(--grey-400) !important;
  pointer-events: auto;

  & mat-icon svg {
    color: var(--grey-400) !important;
  }
}

// Backgrounds
.bg-grey-50 {
  background-color: var(--grey-50);
}

.bg-grey-100 {
  background: var(--grey-100);
}

.bg-primary-100 {
  background: var(--primary-100);
}

// Separador / linea horizontal
hr.divider {
  border: 0;
  border-bottom: 1px solid var(--grey-300);
}

.soft-border-bottom {
  border-bottom: 1px solid var(--grey-200);
}

// Para elementos que son clickables y tienen efectos visuales (como hover)
.action {
  cursor: pointer;

  &:hover {
    color: var(--primary-text);
  }
}

// Shadows
.shadow-standard {
  @include shadow-standard;
}

.shadow-dark {
  @include shadow-dark;
}

.shadow-modal {
  @include shadow-modal;
}

// opacidades

.img-opacity {
  opacity: 0.1;
}

// Para rotar iconos o cosas
.spinner {
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(1, 1, 0, 0);
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-name: spin-animation;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

// Bordes

.border-bottom {
  border-bottom: 1px solid var(--grey-300) !important; // Algunos componentes de material intentan pisarlo
}

.border-top {
  border-top: 1px solid var(--grey-300) !important; // Algunos componentes de material intentan pisarlo
}

.border-right {
  border-right: 1px solid var(--grey-300) !important; // Algunos componentes de material intentan pisarlo
}

// AÑADE COMAS ENTRE DATOS

.comma:not(:last-of-type)::after {
  content: ',';
}

// Icon warn color
.warn-icon.mat-icon {
  color: var(--warn-500) !important;
}

// elimina estilos de navegador button
button {
  border: none;
  color: inherit;
  background: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

.debug-css {
  border: 2px solid red;
}

// elimina por defecto estilos listas
ul,
ol {
  margin: 0 auto;
  list-style: none;
  padding-left: 0;
}

// clases preview visor documentos SMALL
.document-preview-wrapper .ngxImageZoomContainer,
.document-preview-wrapper .ngxImageZoomContainer .ngxImageZoomThumbnail,
.document-preview-wrapper .ngxImageZoomContainer .ngxImageZoomFull {
  width: 210px;
  height: 290px;
}

// clases preview visor documentos LARGE
.document-preview-wrapper-large .ngxImageZoomContainer,
.document-preview-wrapper-large .ngxImageZoomContainer .ngxImageZoomThumbnail,
.document-preview-wrapper-large .ngxImageZoomContainer .ngxImageZoomFull {
  width: 650px;
  height: 903px;

  @media (min-width: 1920px) {
    width: 800px;
    height: 1112px;
  }
}
