@import '../mixins/fonts';

// Estilos modal genéricos

.mat-expansion-panel {
  background-color: var(--grey-50);
}

.mat-expansion-panel.bg-transparent {
  background-color: transparent;
}

.mat-mdc-dialog-container {
  // yo del futuro, no encontramos nada más específico para este padding y overflow, no toques.
  padding: 0 !important;
  background-color: var(--grey-50);
  border-radius: 0;

  & .mat-mdc-dialog-surface {
    overflow: unset !important;
  }

  & .mat-mdc-dialog-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  & .mat-mdc-dialog-actions {
    margin-bottom: 0;
    padding: 1rem 2rem;
    background: var(--grey-50);
    justify-content: flex-end;
    width: 100%;
  }

  & .mat-mdc-dialog-title {
    @include overline-o1;
    margin-bottom: 1em;
    margin-top: -0.6em;
    margin-left: 2em;
  }

  & .mdc-dialog__actions {
    position: absolute;
  }

  & .dialog-container {
    &__close {
      top: -21px;
      right: -11px;
      background: var(--grey-50);
      border-radius: 100%;
      height: 24px;
      width: 24px;
      z-index: 3;

      &--l {
        top: -5px;
      }

      & .mat-icon {
        color: var(--primary-700);
        height: 24px;
        width: 24px;
      }
    }

    &__form {
      margin: 12px 0;
    }

    .title {
      @include overline-o1;
    }
  }
}

// ajusta alto de modales de actores/places
.content {
  max-height: 66vh;
  overflow-y: auto;
  overflow-x: hidden;
}
