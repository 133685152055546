@import 'mixins/fonts';

.body-b1 {
  @include body-b1;
}

.body-b2 {
  @include body-b2;
}

.body-b3 {
  @include body-b3;
}

.body-b4 {
  @include body-b4;
}

.caption-c1 {
  @include caption-c1;
}

.caption-c2 {
  @include caption-c2;
}

.overline-o1 {
  @include overline-o1;
}

.overline-o2 {
  @include overline-o2;
}

.overline-o3 {
  @include overline-o3;
}

.overline-o4 {
  @include overline-o4;
}

.link {
  @include link;
}

.tab {
  @include tab;
}

.title-t0 {
  @include title-t0;
}

.title-t1 {
  @include title-t1;
}

.title-t2 {
  @include title-t2;
}

.label {
  @include label;
}

.actions-a3 {
  @include actions-a3;
}
