@mixin body-b1 {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
}

@mixin body-b2 {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;

  &--bold {
    font-weight: 700;
  }
}

@mixin body-b3 {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04em;
  font-weight: 400;

  &--bold {
    font-weight: 700;
  }
}

@mixin body-b4 {
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.04em;
  font-weight: 600;
}

@mixin caption-c1 {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

@mixin caption-c2 {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
}

@mixin overline-o1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin overline-o2 {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin overline-o3 {
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin overline-o4 {
  font-weight: bold;
  font-size: 8px;
  line-height: 11px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

@mixin link {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--primary-700);
  cursor: pointer;
}

@mixin tab {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

@mixin title-t0 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.05em;
  color: var(--primary-700);

  &--bold {
    font-weight: 900;
    font-size: 32px;
    line-height: 52px;
    color: var(--grey-1000);
  }
}

@mixin title-t1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.05em;
}

@mixin title-t2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
}

@mixin label {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &--grey {
    text-decoration: underline;
    color: var(--grey-700);
  }
}

@mixin actions-a3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--grey-500);
}
